.scangif {
    width: 50%;
    filter: none;
    margin: 0;
}

@media screen and (min-width : 451px) and ( max-width : 820px)  {
    .scangif {
        width: 70%;
        margin-bottom: 3rem;
       
    }
  }
  
  @media screen and ( max-width : 450px ) {
    .scangif {
        width: 90%;
        margin-bottom: .5rem;
       
    }
  }