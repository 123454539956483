 @import url('https://fonts.googleapis.com/css?family=Manrope:600');
 @import url('https://fonts.googleapis.com/css?family=Montserrat');
:root {
  --red: #FF3D33;
  --blue: #0E61CB;
  --purple: #DDD5F4;
  --indigo: #0E0E2C;
  --evergreen: #31D0AA;
  --pink: #e83e8c;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: ##0E61CB;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-primary: Manrope, Montserrat , Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, "poppins";
  --font-family-secondary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.bg-primary{
  background-color: var(--blue) !important;
}
.text-primary {
  color: var(--blue) !important ;
}
.bg-secondary{
  background-color: var(--red) !important;
}
.text-secondary {
  color: var(--red) !important;
}
.bg-tertiary{
  background-color: var(--purple) !important;
}
.text-tertiary {
  color: var(--purple) !important;
}

body {
    font-family: var(--font-family-primary);
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

h1 {
    font-weight: bold;
    color: aqua;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}


.title-s-1 {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  word-wrap: break-word;
  font-family: 'Montserrat'
}

.btn-principal {
  margin-top: .5em;
  bottom: 0;
  width: 100%;
  height: 60px;   /* Height of the footer */
}

/* CSS */
.button-79 {
  
  backface-visibility: hidden;
  background: #004AAD;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  /* position: absolute; */
  text-transform: uppercase;
  text-decoration: none;
  /* transform: translateZ(0) scale(1); */
  /* transition: transform .2s; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* bottom: 0; */
  width: 80%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

/* Style the active link (or home/logo) */
.active {
  color: #FF3D33 !important;
}


.header-1 {

  color: var(--Indigo, #0E0E2C);
  font-family: Manrope;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 0.75em;
  letter-spacing: -1.28px;
}

.header-2 {

  color: var(--Indigo, #0E0E2C);
  font-family: Manrope;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
 letter-spacing: -0.8px;
}
.header-3 {

  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.48px;
}
.text {
  color: var(--Indigo, #0E0E2C);

/* Body */
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 22.4px */
}
.subtitle{
  color: var(--Indigo, #0E0E2C);
font-family: Manrope;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 1rem;
}
.sm-text {
  color: var(--Indigo, #0E0E2C);

/* Small */
font-family: var(--font-family-primary);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
}
.link {
  color: var(--Link-color, #4B4DED);
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration-line: underline;
}
.btn-text {
  color: var(--Indigo, #0E0E2C);
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.64px;
text-transform: uppercase;
}

.card-body {
  padding: 0;
}
.card-header {
  padding: 0.1rem 0 0 0;
}
.center-column {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.l-none {
  list-style: none;
}

.card {
  min-height: 75vh;
}

.placeHolder {
  height: 13vh;
  width: 100%;
  margin: auto;
  bottom: 0;
  position: fixed;
  background-color: red;
}

.btn-disable
        {
        cursor: not-allowed;
        pointer-events: none;

        /*Button disabled - CSS color class*/
        background: rgba(196, 196, 196, 0.63);
        color: #ffffff;
        }

.rounded {
  border-radius: 8px !important;
}

@media screen and (min-width : 451px) and ( max-width : 820px)  {
  .card {
      height: 65%;
     
  }
}
@media screen and ( max-width : 450px ) {
  .card {
      height: 65%;  
  }
}

.scangif {
  width: 50%;
  filter: drop-shadow(4px 8px 4px rgba(0, 0, 0, 0.72));
  margin: 0;
}

@media screen and (min-width : 451px) and ( max-width : 820px)  {
  .scangif {
      width: 70%;
      margin-bottom: 3rem;
     
  }
}

@media screen and ( max-width : 450px ) {
  .scangif {
      width: 90%;
      margin-bottom: .5rem;
     
  }
}
