@import url('https://fonts.googleapis.com/css?family=Manrope:600');
.wrap {
  margin: auto;
  display: flex;
  z-index: 900;
  position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  width: 90svw;
  z-index: 10;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-perso {
  text-align: center;
  border-radius: .5em;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.modal-perso .modal-perso-body {
  background-color: #fff;
  padding: 2em 1em 2em 1em;
  color: #000;
  border-radius: .5em;
  max-width: 100%;
}

.modal-perso .modal-perso-image {

  border-radius: 50%;
  /* padding: 11px 10px 2px; */
  position: relative;
  top: 1.5rem;
  margin: auto;
}

.modal-perso h1.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: .5em;
  color: #000;
}

.modal-perso p.content {
  margin-bottom: 2em;
  color: #000000;
  font-size: 20px;
  font-weight: lighter;
}

.modal-perso button.btn-action,
.modal-perso button.btn-action2 {
  font-size: 0.9em;
  padding: 8px 10px;
  border-radius: .25em;
  cursor: pointer;
  flex: 1;
  min-width: min(120px, 30%);
  max-width: 200px;
  margin: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-perso button.btn-action {
  background-color: #FB4B4B ;
  color: #f5eeed;
  border-color: none;
}
.modal-perso button.btn-action2 {
  background-color: #f5eeed ;
  color: #035AA6;
  width: auto;
}

.modal-cover {
position: fixed;
top: 0px;
bottom: 0px;
left: 0px;
right: 0px;
opacity: 0.5;
z-index: 899;
background-color: #000000;
display: none;
}

.buttons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: nowrap;
  padding: 0 1em;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .modal-perso button.btn-action,
  .modal-perso button.btn-action2 {
    font-size: 0.8em;
    padding: 8px 5px;
    min-width: min(100px, 25%);
    margin: 0 2px;
  }
  
  .buttons {
    gap: 5px;
    padding: 0 0.5em;
  }
}
