
.modal {
    font-size: 12px;
  }
  .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    padding: 15px;
    color: #000 !important ;
    border-radius: 8px;

  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
    color: #000;
  } 
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }  
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }

  .close {
    cursor: pointer;
  }