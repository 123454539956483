.navbar-toggler{
    text-transform: uppercase;
    background-color: #0E61CB;
    color: white;
    /* padding: .3rem 1rem; */
}
.cs-navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}