
.btnPrincipal {
    margin-top: .5em;
    bottom: 0;
    width: 100%;
    height: 60px;   /* Height of the footer */
  }
  
  /* CSS */
  .button79 {
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
    background: #0E61CB;
    border-radius: 8px;
    /* border-radius: .375rem; */
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Montserrat',Circular,Helvetica,sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: -.01em;
    line-height: 1.3;
    padding: 1rem 1.25rem;
    /* position: absolute; */
    text-transform: uppercase;
    text-decoration: none;
    /* transform: translateZ(0) scale(1); */
    /* transition: transform .2s; */
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    /* bottom: 0; */
    width: 90%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
  }
  
  .button79:disabled, .button79[disabled] {
    color: #fff !important;
    background-color: rgba(196, 196, 196, 0.631) !important;
    cursor: auto;

    /* to disable */
    cursor: not-allowed;
    pointer-events: none;
  }