.error-pages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
  }

  .shake-horizontal {
    -webkit-animation: shake-horizontal 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
            animation: shake-horizontal 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  }
  
  .change-color {
    -webkit-animation : change-color 1.5s ease-in-out infinite;
      animation: change-color 1.5s ease-in-out infinite;
  }

  .uniform-size {
    font-size: 1.25rem; /* Taille du texte pour les deux éléments */
    font-weight: bold;
    padding: 15px 20px;
    background-color: #035AA6; /* Couleur de fond */
    color: white; /* Couleur du texte */
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    line-height: 100%;
    width: 85%;
    height: 50px;
  }
  
  .pseudo-button {
    cursor: default;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #035AA6 50%, #C4C4C4 50%);
    background-size: 200% 100%;
    animation: gradient-shift 3s ease infinite;
  }
  
  .error-content {
    margin-top: 50px;
  }
  
  .error-content h2 {
    font-size: 18px;
    color: #888888;
    margin-bottom: 20px;
  }
  
  .error-content img {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .error-content button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #c8c8c8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .advertisement {
    width: 100%;
    text-align: center;
  }
  
  .advertisement img {
    width: 90%;
    max-width: 350px;
    margin: 0 auto;
  }
  