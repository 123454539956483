@import url('https://fonts.googleapis.com/css?family=Manrope:600');
.shake-horizontal {
	-webkit-animation: shake-horizontal 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-horizontal 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.change-color {
  -webkit-animation : change-color 1.5s ease-in-out infinite;
    animation: change-color 1.5s ease-in-out infinite;
}

.uniform-size {
  font-size: 1.25rem; /* Taille du texte pour les deux éléments */
  font-weight: bold;
  padding: 15px 20px;
  background-color: #035AA6; /* Couleur de fond */
  color: white; /* Couleur du texte */
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
  line-height: 100%;
  width: 85%;
  height: 50px;
  text-transform: uppercase;
}

.pseudo-button {
  cursor: default;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, #035AA6 50%, #C4C4C4 50%);
  background-size: 200% 100%;
  animation: gradient-shift 3s ease infinite;
}


.banner-container {
  width: 95%;
  max-width: 1200px; /* Limiter la largeur maximale pour les grands écrans */
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto; /* Centrer horizontalement */
  padding: 10px;
  border-radius: 5px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajuste l'image pour couvrir toute la bannière */
  border-radius: 5px; /* Garde les coins arrondis */
}

.icon-bar .icon {
  transition: color 0.3s;
}

.icon-bar .icon:hover {
  color: #555; /* Changer la couleur lors du survol */
}

.scangif {
  width: 45%;
  margin: 0;
}


@media screen and (min-width : 451px) and ( max-width : 820px)  {
  .scangif {
      width: 60%;
      margin-bottom: 3rem;
     
  }
}

@media screen and ( max-width : 450px ) {
  .scangif {
      width: 80%;
      margin-bottom: .5rem;
     
  }
}


/* Pour les écrans plus petits comme les téléphones mobiles */
@media (max-width: 768px) {
  .banner-container {
    height: 20px; /* Réduire la hauteur pour les petits écrans */
    padding: 5px; /* Réduire l'espacement */
  }

  .banner-image {
    height: 100%; /* Garder l'image responsive avec la nouvelle hauteur */
  }
}

/* Pour les très petits écrans */
@media (max-width: 480px) {
  .banner-container {
    height: 80px; /* Réduire encore plus la hauteur */
    padding: 5px;
  }

  .banner-image {
    height: 70%;
  }
}

@keyframes gradient-shift {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200% 0;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2023-3-14 21:42:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  @keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }


  @-webkit-keyframes change-color {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              color: red;
    }
    50% {
        color: #004AAD;
    }
  }

  @keyframes change-color {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              color: red;
    }
    50% {
        color: #004AAD;
    }
    /* 10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    } */
  }
  